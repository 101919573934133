<template>
    <div class="flex overflow-hidden flex-col leading-none bg-white">
      <header class="flex overflow-hidden flex-wrap gap-5 justify-between items-start px-6 pt-5 w-full text-3xl bg-black text-neutral-100 max-md:px-5 max-md:max-w-full">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a16472d60c8cddfeeeffbdcbbc82d45a108b98b47a1c0aa31acb82afea39e970?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="Company logo" class="object-contain shrink-0 max-w-full aspect-[1.81] w-[156px]" />
        <nav class="flex flex-wrap gap-5 justify-between items-start mt-3.5 max-md:max-w-full">
          <a href="#" class="overflow-hidden gap-2 self-stretch p-2 whitespace-nowrap rounded-lg bg-blend-normal">BOX</a>
          <div class="flex flex-col self-stretch whitespace-nowrap">
            <a href="#" class="overflow-hidden gap-2 self-end p-2 rounded-lg bg-blend-normal">WOD</a>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1db491ae641ea0b841ef0b5bff6ba1f5620e7e09c07c3d20e8cd68448eca408d?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="" class="object-contain mt-3 w-6 aspect-square" />
          </div>
          <a href="#" class="overflow-hidden gap-2 self-stretch p-2 whitespace-nowrap rounded-lg bg-blend-normal">Reservation</a>
          <a href="#" class="gap-2 self-stretch p-2 whitespace-nowrap rounded-lg bg-blend-normal">Community</a>
          <a href="#" class="gap-2 self-stretch p-2 rounded-lg bg-blend-normal text-zinc-100">My Page</a>
          <button class="flex overflow-hidden gap-2 justify-center items-center px-3 py-4 text-base text-black whitespace-nowrap bg-white rounded-lg min-h-[49px]">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/fccc67eb8ca6167e637281c9ca1fe8ad96c403dbd242716a97822cacf0e95947?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="" class="object-contain shrink-0 self-stretch my-auto w-4 aspect-square" />
            <span class="self-stretch my-auto">LOGOUT</span>
          </button>
        </nav>
      </header>
      <main class="flex flex-col pt-14 pr-20 pb-56 pl-9 w-full bg-zinc-300 max-md:px-5 max-md:pb-24 max-md:mr-1.5 max-md:max-w-full">
        <h1 class="self-start text-black text-[179px] max-md:max-w-full max-md:text-4xl">MEMBER'S</h1>
        <section class="flex flex-wrap gap-5 justify-between items-start self-end px-12 py-8 mt-20 text-3xl text-black bg-white max-md:px-5 max-md:mt-10">
          <div class="flex flex-col self-stretch whitespace-nowrap max-md:max-w-full">
            <div class="flex gap-5 justify-between ml-14 max-w-full w-[466px]">
              <div>NAME</div>
              <div class="font-medium">권채훈</div>
            </div>
            <div class="flex flex-col pl-2.5 mt-5 w-full max-md:max-w-full">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/73200d6077b1aa176e2eba2c704d65148a2cf8dc5d48b2af664907a293f1c838?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="" class="object-contain w-full aspect-[333.33] max-md:max-w-full" />
              <div class="flex gap-5 justify-between mt-5 ml-8 max-w-full w-[465px]">
                <div>STATUS</div>
                <div class="font-medium">정지</div>
              </div>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/fb5ecfd6a4e05b5ad6b80c46010dd839467b64b9188938e8dabffad481888081?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="" class="object-contain mt-5 w-full aspect-[333.33] max-md:mr-1 max-md:max-w-full" />
              <div class="flex flex-wrap gap-5 justify-between mt-8 max-w-full w-[561px]">
                <div>REGISTER</div>
                <div class="font-medium">2024-07-01</div>
              </div>
            </div>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7e74a73b10ec381936cb6e72dbda3ff2432fe111727110a5c44c123074191cc5?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="" class="object-contain mt-6 w-full aspect-[333.33] max-md:mr-1.5 max-md:max-w-full" />
            <div class="flex flex-wrap gap-5 justify-between mt-7 ml-16 max-w-full w-[506px]">
              <div>END</div>
              <div class="font-medium">2024-08-10</div>
            </div>
          </div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba373c6fc59d978cc28da86960c4419f532ab4c5b689df046e8b70ea180ff07a?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="" class="object-contain shrink-0 mt-1.5 aspect-[0.02] w-[5px]" />
          <div class="flex flex-col mt-1.5 max-md:max-w-full">
            <div class="flex flex-col items-start pl-3.5 w-full max-md:max-w-full">
              <div class="flex flex-wrap gap-5 justify-between ml-3 max-w-full w-[612px]">
                <div>PHONE NO</div>
                <div class="font-medium">010-1234-5678</div>
              </div>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6e40993978e276bda661623a2acedfb2f2c49ee02e2a3001797a9e87ecd74d6?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="" class="object-contain self-stretch mt-4 w-full aspect-[333.33] max-md:max-w-full" />
              <div class="flex flex-wrap gap-5 justify-between mt-6 max-w-full w-[549px]">
                <div>DEAD LIFT</div>
                <div class="font-medium">000.0</div>
              </div>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6e40993978e276bda661623a2acedfb2f2c49ee02e2a3001797a9e87ecd74d6?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="" class="object-contain self-stretch mt-5 w-full aspect-[333.33] max-md:max-w-full" />
              <div class="flex flex-wrap gap-5 justify-between mt-7 ml-14 max-w-full whitespace-nowrap w-[495px]">
                <div>SQUAT</div>
                <div class="font-medium">000.0</div>
              </div>
            </div>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6e40993978e276bda661623a2acedfb2f2c49ee02e2a3001797a9e87ecd74d6?apiKey=1d6aab70bf8e4c31b4378ebfef321386&&apiKey=1d6aab70bf8e4c31b4378ebfef321386" alt="" class="object-contain mt-6 ml-3.5 w-full aspect-[333.33] max-md:max-w-full" />
            <div class="flex flex-wrap gap-5 justify-between mt-5 max-w-full w-[562px]">
              <div>BENCH PRESS</div>
              <div class="font-medium">000.0</div>
            </div>
          </div>
        </section>
        <section class="flex z-10 flex-wrap gap-5 mt-14 w-full max-w-[1778px] max-md:mt-10 max-md:max-w-full">
          <h2 class="flex-auto text-[170px] text-neutral-700 max-md:max-w-full max-md:text-4xl">RESERVATION</h2>
          <div class="flex-auto my-auto text-3xl text-black">ALL ▼</div>
        </section>
        <section class="flex flex-col self-end px-10 pt-8 pb-16 w-full text-3xl font-medium text-black whitespace-nowrap bg-white max-w-[1752px] max-md:px-5 max-md:mr-1.5 max-md:max-w-full">
          <div class="flex gap-5 self-center w-full max-w-[1563px] max-md:max-w-full">
            <div class="flex-auto">DATE</div>
            <div class="flex-auto">TIME</div>
            <div class="flex-auto">coach</div>
            <div>wod</div>
            <div class="flex-auto">RECORD</div>
          </div>
          <hr class="shrink-0 mt-6 ml-4 w-full h-[5px]" />
          <div class="flex flex-wrap gap-5 mt-4 ml-8 max-w-full w-[1233px]">
            <div class="flex-auto">2024-07-18</div>
            <div class="my-auto">10:00</div>
            <div class="flex-auto">HomeTax</div>
            <div class="flex flex-col self-start text-xl">
              <button class="px-16 py-1.5 bg-zinc-300 max-md:px-5">VIEW</button>
            </div>
          </div>
          <hr class="shrink-0 mt-3.5 ml-4 w-full h-0.5" />
          <div class="flex gap-5 mt-4 ml-8 w-full max-w-[1548px] max-md:max-w-full">
            <div class="flex-auto my-auto">2024-07-13</div>
            <div>10:00</div>
            <div class="flex-auto my-auto">HomeTax</div>
            <div class="flex flex-col self-start mt-2 text-xl">
              <button class="px-16 py-1.5 bg-zinc-300 max-md:px-5">VIEW</button>
            </div>
            <button class="self-start text-xl text-white">CREATE</button>
          </div>
          <hr class="shrink-0 mt-3.5 w-full h-0.5 max-md:mr-2" />
          <div class="flex flex-wrap gap-5 items-start mt-4 mr-10 ml-8 w-full max-w-[1601px] max-md:mr-2.5 max-md:max-w-full">
            <div class="flex-auto self-stretch">2024-07-10</div>
            <div>10:00</div>
            <div class="flex-auto mt-2.5">HomeTax</div>
            <div class="flex flex-col mt-2 text-xl">
              <button class="px-16 py-1.5 bg-zinc-300 max-md:px-5">VIEW</button>
            </div>
            <div class="self-stretch px-11 py-1.5 my-auto text-xl bg-zinc-300 max-md:px-5">SUCCESS</div>
          </div>
          <hr class="shrink-0 mt-4 mr-3.5 w-full h-0.5 max-md:mr-2.5" />
          <div class="flex flex-wrap gap-5 mt-3.5 mr-10 ml-8 w-full max-w-[1601px] max-md:mr-2.5 max-md:max-w-full">
            <div class="flex-auto">2024-07-09</div>
            <div class="my-auto">10:00</div>
            <div class="flex-auto my-auto">HomeTax</div>
            <div class="flex flex-col self-start mt-1.5 text-xl">
              <button class="px-16 py-1.5 bg-zinc-300 max-md:px-5">VIEW</button>
            </div>
            <div class="self-start px-11 py-1.5 mt-1.5 text-xl bg-zinc-300 max-md:px-5">SUCCESS</div>
          </div>
          <hr class="shrink-0 mt-5 w-full h-0.5 max-md:mr-2.5" />
          <div class="flex flex-wrap gap-5 mt-4 mr-10 ml-8 w-full max-w-[1601px] max-md:mr-2.5 max-md:max-w-full">
            <div class="flex-auto">2024-07-03</div>
            <div>10:00</div>
            <div class="flex-auto">HomeTax</div>
            <div class="flex flex-col self-start mt-1.5 text-xl">
              <button class="px-16 py-1.5 bg-zinc-300 max-md:px-5">VIEW</button>
            </div>
            <div class="self-start px-12 py-1.5 mt-2 text-xl bg-zinc-300 max-md:px-5">FAILURE</div>
          </div>
          <hr class="shrink-0 mt-5 w-full h-0.5 max-md:mr-2.5" />
          <div class="flex flex-wrap gap-5 items-start mt-4 mr-10 ml-8 w-full max-w-[1601px] max-md:mr-2.5 max-md:max-w-full">
            <div class="flex-auto">2024-07-01</div>
            <div>10:00</div>
            <div class="flex-auto">HomeTax</div>
            <div class="flex flex-col mt-2.5 text-xl">
              <button class="px-16 py-1.5 bg-zinc-300 max-md:px-5">VIEW</button>
            </div>
            <div class="px-11 py-1.5 text-xl bg-zinc-300 max-md:px-5">SUCCESS</div>
          </div>
          <hr class="shrink-0 mt-3 w-full h-0.5 max-md:mr-2.5" />
        </section>
        <nav class="flex gap-5 justify-between self-center mt-4 mb-0 ml-3.5 text-3xl text-black whitespace-nowrap max-md:mb-2.5" aria-label="Pagination">
          <button class="px-2 bg-white h-[46px] w-[46px]" aria-label="Previous page">◁</button>
          <button class="px-3.5 bg-white h-[46px] w-[46px]" aria-current="page">1</button>
          <button class="px-3.5 bg-white h-[46px] w-[46px]" aria-label="Next page">▷</button>
        </nav>
      </main>
    </div>
  </template>
  
  <script>
  
  export default ({

  })
  </script>
